const status = {
  ok: 'ok',
  error: 'error',
  loading: 'loading',
  feedback: 'feedback',
}

export default status

export { status }
